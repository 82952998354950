

import { Component } from "vue-property-decorator";
import AddComment from "@/views/AddComment.vue";
import { AppraisalReceived} from "@/models/tasks.model";
import { BASE_API_URL,EventBus} from "../../../../../config";
import Axios from "axios";
import Datepicker from "vuejs-datepicker";

import DisplayFiles from "@/views/DisplayDocument.vue";
import CreditAuthorization from "@/views/CreditAuthorizationInTask.vue";
import { ALLOWED_FILE_EXTENSIONS } from "@/constants";
import PipeLineSocketMixin from "@/mixins/PipeLineSocketMixin"
import CommonMixin from "@/mixins/common.mixin";
import { mixins } from "vue-class-component";

@Component({ components: { AddComment, DisplayFiles, CreditAuthorization, Datepicker } })
export default class AppraisalReceivedComponent extends mixins(PipeLineSocketMixin, CommonMixin) {

  
  public allowedFileExtensions = ALLOWED_FILE_EXTENSIONS;
  public appraisalReceived= new AppraisalReceived();
  public pipeLineHeaderData: any = {};
  public loanTxnId = null;
  public taskNameForRoom = 'appraisal-received';

 private playWeLearnVideo() {
    EventBus.$emit("openWeLearn", { taskName: "Appraisal Received", videoUrl: 'https://wemlo.s3.amazonaws.com/Common/taskdemo/AppraisalReceived.mp4' });
  }
  public async fetchAppraisalReceivedTaskDetail() {
    try {
      this.$store.state.wemloLoader = true;
      let response = await Axios.post(
        BASE_API_URL + "additionalTask/fetchAppraisalReceivedTaskDetails",
        { loanTxnId: this.loanTxnId });

      this.pipeLineHeaderData = response.data.pipeLineHeaderData;
      this.appraisalReceived = response.data.appraisalReceived;
      this.$store.state.wemloLoader = false;
    } catch (error) {
      console.log(error);
      this.$store.state.wemloLoader = false;
    }
  }

  public async submit(ifSave) {
    try {
      ifSave = ifSave == "Save" ? true : false;
      let validComment = await this.$refs.comment["validateComment"](ifSave);

      if (
        (ifSave ? false  : !(await this.$validator.validateAll())) ||
        !validComment 
      )
        return;
      this.$store.state.wemloLoader = true;
      let formData = new FormData();

      formData.append("loanTxnId", this.loanTxnId);

      /**
       * Appending comment data and comment attachments here in formdata
       */
      let commentObj: any = {
        comment: this.appraisalReceived.commentData.comment,
        showCommentToBroker: this.appraisalReceived.commentData.showCommentToBroker
      };
      if (
        this.appraisalReceived.commentData.commentAttachment.length > 0 &&
        !this.appraisalReceived.commentData.commentAttachment[0].hasOwnProperty("path")
      )
        this.appraisalReceived.commentData.commentAttachment.forEach(file => {
          formData.append("commentAttachment", file);
        });
      else if (this.appraisalReceived.commentData.commentAttachment.length > 0)
        commentObj.commentAttachment = this.appraisalReceived.commentData.commentAttachment;
      formData.append("commentData", JSON.stringify(commentObj));

        if (this.appraisalReceived.appraisalReportInvoice && !this.appraisalReceived.appraisalReportInvoice.hasOwnProperty('path'))
        this.appraisalReceived.appraisalReportInvoice.forEach(file => {
          formData.append("appraisalReportInvoice", file);
        });

      /**
       * Merging the save or complete option here with wemlo invoice and options
       */
      formData.append("ifSave", ifSave);
      formData.append("options", JSON.stringify(this.appraisalReceived.options));

      let response = await Axios.post(
        BASE_API_URL + "additionalTask/appraisalReceived",
        formData);

      this.$snotify[
        response.data.status == 200
          ? "success"
          : response.data.status == 403
          ? "info"
          : "error"
      ](response.data.message);

      if (response.data.status == 200) {
        this.saveEvent();
      }
      
      EventBus.$emit('closeWeLearn',false); // Closing learn more video modal;

      if (!ifSave) {
        this.$router.push({ path: "dashboard" });
      }
    } catch (error) {
      console.log(error);
      const infectedFiles = this.checkResponseIfFileInfected(error);
      infectedFiles.forEach(filename => {
        this.removeInfectedFile(filename)
      })
    } finally {
      this.$store.state.wemloLoader = false;
    }
  }
  public removeInfectedFile(filename: string): void {
    // check if comment attachment contains infected filename
    if (
      this.appraisalReceived.commentData.commentAttachment.length > 0 &&
      !this.appraisalReceived.commentData.commentAttachment[0].hasOwnProperty("path")
    )
    {
      this.appraisalReceived.commentData.commentAttachment = this.appraisalReceived.commentData.commentAttachment.filter(file => (file.name !== filename));
    }
    if (
      this.appraisalReceived.appraisalReportInvoice &&
      !this.appraisalReceived.appraisalReportInvoice.hasOwnProperty("path")
    ) {
      this.appraisalReceived.appraisalReportInvoice = this.appraisalReceived.appraisalReportInvoice.filter(file => (file.name !== filename));
    }
  }

  /**
   * To upload appraisal report invoice
   */
  public uploadAppraisalReportInvoice(event) {
    try {
       if (event.target.files.length > 0) {
      Object.values(event.target.files).forEach(element => {
      this.appraisalReceived.appraisalReportInvoice.push(element)
    })
    }
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * To display  appraisal report invoice file
   */
  public viewAppraisalReportInvoice(file,fileName) {
      this.$refs.comment["displayCommentAttachment"](file, true,fileName);
  }
  public deleteDoc(obj){
     this.appraisalReceived[obj.deleteData].splice(obj.index,1)
  }
public async  deleteFile(obj){
    let index = this.appraisalReceived[obj.deleteData].findIndex(
      doc => doc.path == obj.files
    );
  this.appraisalReceived[obj.deleteData].splice(index,1)
      await this.deleteDisplayFromTaskCollection(index,obj.deleteData);
}
 public async deleteDisplayFromTaskCollection(index, categoryName) {
    try {
      let response = await Axios.post(
        BASE_API_URL + "wemloprocessor/deleteDocumentByCategoryName",
        { loanTxnId: this.loanTxnId, index: index, categoryName: categoryName });
    } catch (error) {
      console.log(error);
    }
  }

  async mounted() {
    this.loanTxnId = this.$route.query.id;
   await this.fetchAppraisalReceivedTaskDetail();
  }
}
